import {getPathWithQuery, getRecord, putRecord} from "@/services/api";

export function getTransfer(reference_number, options = {}) {
    return getRecord('/prologistics/tracking/transfers/' + reference_number + '.json', {}, options = {})
}

export function getTransferDetail(reference_number, postcode, options = {}) {
    return getRecord('/prologistics/tracking/transfers/' + reference_number + '/details.json', {postcode: postcode}, options)
}

export function putTransfer(reference_number, postcode, token, data, options = {}) {
    return putRecord(getPathWithQuery('/prologistics/tracking/transfers/' + reference_number + '.json', {
            postcode: postcode,
            token: token
        }),
        data,
        options
    )
}